import styled from '@emotion/styled'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Filters } from './Filters'
import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const RoomsList = memo(function RoomsList({ items }: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Filters rooms={items} />

      {uniqBy(items, 'title').map((item, index) => {
        const section = item.title
          ? item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()
          : undefined
        return (
          <Item
            key={index}
            variant={index % 2 ? 'default' : 'dark'}
            {...item}
            id={`room-${section}`}
          />
        )
      })}
    </Container>
  )
})

const Container = styled.section``
