import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import React, { memo } from 'react'

interface ItemProps {
  icon?: string
  title: string
}

export interface Props {
  items: ItemProps[]
  title?: string
}

export const Features = memo(function Features({ items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <TitleWrapper>
        <StyledDots width={6} height={5} variant="beige" />
        {title ? <Title text={title} /> : null}
      </TitleWrapper>
      <Items>
        {items.map((item, index) => (
          <Item key={index}>
            <FadeInUp delay={index * 0.05}>
              {item.icon ? (
                <Icon src={item.icon} alt={item.title} width={16} height={16} />
              ) : null}
              <Name>{item.title}</Name>
            </FadeInUp>
          </Item>
        ))}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8vw 10vw;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};

  @media (max-width: 991px) {
    padding: 70px 30px;
    display: block;
    margin-top: 30px;
  }
`

const TitleWrapper = styled.div`
  position: relative;
  width: 26%;
  height: 100%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledDots = styled(Dots)`
  @media (max-width: 991px) {
    margin: 0 auto 20px auto;
  }
`

const Title = styled(AnimatedTitle)`
  position: absolute;
  top: 45%;
  left: 50%;
  width: 100%;
  max-width: max-content;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.77vw;
  line-height: 1.15;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 74%;
  margin-bottom: -1.38vw;

  @media (max-width: 1439px) {
    margin-bottom: -20px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(50% - 8vw);
  margin-bottom: 1.38vw;
  margin-left: 7vw;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media (max-width: 1439px) {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
`

const Icon = styled.img`
  margin-right: 1.11vw;
  width: 1.11vw;
  height: 1.11vw;

  @media (max-width: 1439px) {
    margin-right: 16px;
    width: 16px;
    height: 16px;
  }
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.9vw;
  line-height: 1.5;

  @media (max-width: 1439px) {
    font-size: 13px;
  }
`
